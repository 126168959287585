<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        A compound is known to consist solely of carbon, hydrogen, and chlorine. Through elemental
        analysis, it was determined that the compound is composed of
        <stemble-latex :content="pctCarbon" />
        carbon and
        <stemble-latex :content="pctHydrogen" />
        hydrogen.
      </p>

      <p class="mb-2">a) What is the empirical formula of this compound?</p>

      <chemical-notation-input v-model="inputs.empFormula" class="mb-5" :disabled="!allowEditing" />

      <p class="mb-2">
        b) If the molar mass of this compound is
        <stemble-latex :content="molarMass" />
        what is its molecular formula?
      </p>

      <chemical-notation-input v-model="inputs.molFormula" class="mb-0" :disabled="!allowEditing" />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput';

export default {
  name: 'Question222',
  components: {
    ChemicalNotationInput,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        empFormula: null,
        molFormula: null,
      },
    };
  },
  computed: {
    versionVariable() {
      return this.taskState.getValueBySymbol('versionVariable').content;
    }, // 1 = C2H4Cl2, 2 = C2H2Cl4, 3 = C2H2Cl2
    molarMass() {
      if (this.versionVariable.value === 1) {
        return '$98.96\\text{ g/mol,}$';
      } else if (this.versionVariable.value === 2) {
        return '$167.85\\text{ g/mol,}$';
      } else if (this.versionVariable.value === 3) {
        return '$96.94\\text{ g/mol,}$';
      } else {
        return '';
      }
    },
    pctCarbon() {
      if (this.versionVariable.value === 1) {
        return '$24.27\\%$';
      } else if (this.versionVariable.value === 2) {
        return '$14.31\\%$';
      } else if (this.versionVariable.value === 3) {
        return '$24.78\\%$';
      } else {
        return '';
      }
    },
    pctHydrogen() {
      if (this.versionVariable.value === 1) {
        return '$4.03\\%$';
      } else if (this.versionVariable.value === 2) {
        return '$1.20\\%$';
      } else if (this.versionVariable.value === 3) {
        return '$2.02\\%$';
      } else {
        return '';
      }
    },
  },
};
</script>
